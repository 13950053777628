import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
    es: {
        helpButton: "Ayuda ℹ️",
        tutorial: "Tutorial",
        chat: "Chat",
        name: "Nombre",
        email: "E-mail",
        birthDate: "Fecha de nacimiento",
        username: "Usuario",
        yes: "Si",
        no: "No",
        active: "Activo",
        role: "Rol",
        sex: "Sexo",
        surname: "Apellido",
        loading: "Cargando",
        language: "Idioma",
        spanish: "Español",
        english: "Inglés",
        group: "Grupo",
        groups: "Grupos",
        timeZone: "Zona horaria",
        admin: "Administrador",
        users: "Usuarios",
        Male: "Hombre",
        Female: "Mujer",
        NonBinary: "No binario",
        NotAnswered: "Prefiero no decirlo",
        male: "Hombre",
        female: "Mujer",
        nonBinary: "No binario",
        notAnswered: "Prefiero no decirlo",
        roles: "Roles",
        password: "Contraseña",
        User: "Usuario",
        mod: "Moderador",
        back: "Volver",
        new: "Nuevo",
        massive: "Masivo",
        new_problem: "Nuevo problema",
        new_collection: "Nueva colección",
        new_group: "Nuevo grupo",
        problems: "Problemas",
        instructionalModel: "Modelo instruccional",
        collections: "Colecciones",
        problem_name: "Nombre del problema",
        problem_text: "Texto del problema",
        filename: "Nombre del archivo",
        enter_problem_xml: "Por favor, introduzca el XML del problema",
        enter_collection_filename:
            "Por favor, introduzca el nombre del archivo de la colección",
        search_select_problem: "Busca o selecciona un problema...",
        group_modified_incorrect: "No se ha podido modificar el grupo",
        search_problem: "Busca un problema...",
        select_problem: "Selecciona un problema",
        no_options: "Sin opciones",
        empty_collection: "Colección vacía",
        current_problems: "Problemas actuales",
        RANDOM: "Aleatorio",
        SINGLE: "Individual",
        PAIR: "Parejas",
        AGENTS: "Agentes",
        congratulationsCollection:
            "¡Felicidades! ¡Ya estás un paso más cerca de dominar las matemáticas!",
        delete: "Eliminar",
        logs: "Registros",
        event: "Evento",
        filter: "Filtrar",
        operand: "Operador",
        Sequence: "Secuencia",
        SEQUENCE: "Secuencia",
        USER: "Usuario",
        massive_register: "Registro masivo",
        send: "Enviar",
        resetProblem: "¿Seguro que quieres reiniciar el problema?",
        cancel: "Cancelar",
        confirmReset: "Entendido, ¡reinicia!",
        pageNotFound:
            "¡Ups! Parece que no podemos encontrar la página que estás buscando",
        backPreviousPage: "Volver",
        serchBarText: "Buscar",
        serchBarButton: "Borrar búsqueda",
        update: "Actualizar",
        new_instructionalModel: "Nuevo modelo instruccional",
        edit_instructionalModel: "Editar modelo instruccional",
        isDefaultInstructionalModel: "Modelo instruccional por defecto",
        DidacticSeq: {
            didacticSeq: "Secuencia Didáctica",
            newDidacticSeq: "Nueva secuencia didáctica",
            createDidacticSeq: "Crear Secuencia Didáctica",
            createIncorrect: "No se ha podido crear la Secuencia Didáctica",
            succesNewDidacticSeq: "¡Secuencia Didáctica creada con éxito!",
            succesModDidacticSeq: "¡Secuencia Didáctica modificada con éxito!",
            modifieErrorDidacticSeq:
                "No se ha podido modificar la Secuencia Didáctica",
            editDidacticSeq: "Editar Secuencia Didáctica",
        },
        alert: {
            ok: "Elemento añadido!!",
            repeated: "Error: existe ya un elemento con este nombre",
            missname: "Error: indica un nombre para el elemento",
            success: "success",
            danger: "danger",
        },
        error: {
            name: "Por favor, escribe un nombre válido",
            fileType: "Únicamente se pueden mandar archivos ZIP",
        },
        AdminNewInstructionalTemplatePage: {
            zipFile: "Fichero ZIP",
            succesNew: "El modelo instruccional ha sido creado con éxito",
        },
        AdminEditInstructionalTemplatePage: {
            zipFile: "Fichero ZIP",
            succesEdit: "El modelo instruccional ha sido editado con éxito",
        },
        UserDataTable: {
            passwordChange: "Necesario en caso de cambio de contraseña",
            register_correct: "¡Usuario registrado con éxito!",
            register_usernameIncorrect:
                "¡Vaya! Ya existe alguien con ese nombre de usuario",
            register_incorrect: "¡Ups! Parece que algo ha ido mal",
            updateUser_correct: "¡Usuario actualizado con éxito!",
            password_error: "Las contraseñas introducidas no coinciden",
            editUser: "Editar usuario",
            account: "Datos personales",
            register: "Registrar usuario",
            repeatPassword: "Repita la contraseña",
            surnames: "Apellidos",
            accept: "Aceptar",
        },
        SignUpMassive: {
            massiveRegister_correct: "¡Usuarios registrados con éxito!",
            massiveRegister_usernameIncorrect:
                "¡Vaya! Ya existe un usuario con uno de esos nombres",
            massiveRegister_incorrect: "",
            register_users: "Registrar usuarios",
            prefix: "Prefijo",
            numUsers: "Número de usuarios",
        },
        ListUsers: {
            create_user: "Registrar un usuario",
            registerMassive: "Registro varios usuarios",
        },
        LoginForm: {
            login_error: "El nombre de usuario o la contraseña son incorrectos",
            login: "Iniciar sesión",
        },
        AdminCollectionTable: {
            hidden: "Oculto",
            edit_collection: "Editar colección",
            enter_collection_name: "Please enter the collection name",
            collection_created_correct: "¡Colección creada con éxito!",
            collection_modified_correct: "¡Colección modificada con éxito!",
            collection_created_incorrect: "No se ha podido crear la colección",
            collection_modified_incorrect:
                "No se ha podido modificar la colección",
            group_created_correct: "¡Grupo creado con éxito!",
            group_modified_correct: "¡Grupo modificado con éxito!",
            group_created_incorrect: "No se ha podido crear el grupo",
            group_name_already_exists: "Ya existe un grupo con ese nombre",
            available_problems: "Problemas disponibles",
            create_collection: "Crear colección",
        },
        AdminGroupTable: {
            edit_group: "Editar grupo",
            enter_group_name: "Nombre del grupo",
            available_users: "Usuarios disponibles",
            available_collections: "Colecciones disponibles",
        },
        SelectSearch: {
            search_select_options:
                "Busca o selecciona una o varias opciones...",
        },
        FinishedCollectionModal: {},
        ProblemTable: {
            edit_problem: "Actualizar problema",
            create_problem: "Crear problema",
            problem_created_correct: "¡Problema creado con éxito!",
            problem_name_already_exists: "Ya existe un problema con ese nombre",
            error_updating_problem:
                "Ha habido un error actualizando el problema",
            error_creating_problem: "Ha habido un error creando el problema",
            problem_updated_correct:
                "¡El problema ha sido actualizado con éxito!",
        },
        AdminLogsPage: {
            allTypes: "Todos los tipos",
            type: "Tipo",
            download: "Descargar",
            dateTime: "Fecha y hora",
            subject: "Usuario",
            roomId: "RoomId",
            intent: "Intent",
            wrapperId: "Colección",
            deleteFilters: "Borrar filtros",
            problem: "Problema",
            from: "Desde",
            to: "Hasta",
        },
        HomePage: {
            problems_info:
                "Cada colección tiene una serie de problemas asociados ¡y te ayudaré a resolverlos!",
            users_info:
                "¡Puedes cambiar tu nombre, contraseña, fecha de nacimiento y más cosas!",
            logout_info:
                "Puedes salir ¡y estaré esperando para que hagamos más problemas de matemáticas!",
            logoutWarning:
                "Si sales, todos las colecciones (y problemas) que estabas haciendo se resetearán ¿seguro que deseas salir?",
            care: "¡Atención!",
            logout: "Cerrar sesión",
            editProfile: "Editar perfil",
            letsDoMath: "¡Vamos a hacer matemáticas!",
            welcomeHomeMessage:
                "¡Hola niños y niñas! ¿Queréis mejorar vuestras habilidades en matemáticas? ¡Pues bienvenidos a TUTORCHAT! Somos una aplicación de inteligencia artificial diseñada para ayudaros a aprender y mejorar vuestros conocimientos en un entorno divertido y motivador. Con nuestra herramienta adaptativa, podréis practicar problemas de diferentes niveles de dificultad y aprender a través de    juegos y actividades divertidas. Además, podréis trabajar a vuestro propio ritmo y obtener retroalimentación y consejos para superar las áreas en las que tengáis dificultades. ¡Así que no esperes más y empecemos a aprender juntos! ¡Estamos seguros de que podréis mejorar vuestras habilidades en matemáticas y divertirnos al mismo tiempo!",
        },
        NavigationBar: {
            home: "Inicio",
            edit_profile: "Editar perfil",
            administration: "Administración",
        },
        CollectionsDataTable: {
            sequence: "Secuencia",
            onProgress: "En progreso",
            selection: "Selección de problemas",
            groupStrategy: "Colaborativo",
            pair: "Parejas",
            timer: "Temporizador",
            info: "Información",
            helpIntroduction:
                "¡Recuerda! En una colección puede haber uno o más problemas matemáticos (los que deberás resolver).Además, cada colección puede tener diferentes ajustes:",
            nextProblemSelection: "Selección del próximo problema",
            collaborativeInfo:
                "¿Eliges tú el problema o haces todos los de la colección?",
            manual: "Manual",
            manualInfo:
                "Elige un problema. Cuando lo termines habrás terminado la colección (si la colección solamente tiene un problema, entrarás directamente).",
            sequenceInfo:
                "Deberás resolver todos los problemas de la colección, uno tras otro.",
            individualOrCollaborative: "Individual o Colaborativo",
            individualOrCollaborativeInfo:
                "Puedes resolver el problema solamente tú (individual) o con más personas (colaborativo).",
            timerInfo:
                "Si ves este icono significa que tendrás que resolver el problema antes de que se termine el tiempo.",
            onProgressInfo:
                "Si te has ido...¡no pasa nada! Podrás seguir resolviendo el último problema en el que estabas donde lo dejaste.",
            finalInfo:
                "Recuerda volver aquí si no te acuerdas de alguna cosa. ¡Suerte!",
            resumeLastCollection: "Continuar resolviendo la última colección",
        },
        AdminCollectionPage: {
            zeroElementsSelected: "0 elementos seleccionados",
            element: "elemento",
            elements: "elementos",
            selected: "seleccionado",
            rowspage: "Filas por página:",
            of: "de",
        },
        MessageBar: {
            sendActionHand: "Realizar acción ☝️",
            sendActionDescription: "Puedes escribir una acción (ecuación, variable, etc.) y hacer clic en un botón para enviarlo al tutor. 🤖🎓",
            sendAction: "Mandar acción al tutor",
            messagesOnlyToUsers: "Mensajes a usuarios 👨‍🎓👩‍🎓",
            messagesToUsersAndTutor: "Mensajes a Tutor 🤖🎓",
        },
        ConversationPage: {
            languageHeader: "spanish",
            waitingReports:
                "¡Esperemos a que el resto de compañeros y compañeras manden su formulario!",
            runOutOfAllowedActions: "¡No se permiten más acciones!",
            skipProblem: "Saltarse el problema",
        },
        LeftPanel: {
            notes: "Notas",
            equations: "Ecuaciones",
        },
        AdminCollectionEditorPage: {
            file: "Fichero",
        },
        Notebook: {
            erase: "Borrar",
            save: "Guardar",
        },
        Chat: {
            chat: "Chat",
        },
        FinishedProblemBar: {
            continue:
                "¡Felicidades! Has resuelto el problema (Presiona para continuar)",
        },
        TimedOutModal: {
            timedOutTitle: "¡Ups! El reloj dice que ya es hora de terminar 🕒",
            timedOutDescriptionLastProblem:
                "El tiempo para resolver nuestro último problema ya se ha terminado, ¡nos vemos pronto!",
            timedOutDescription:
                "¡Tic-tac! El tiempo se nos ha terminado, pero podemos seguir resolviendo problemas",
        },
        ResetButton: {
            reset: "Reiniciar",
        },
        BookshelfLoader: {
            startingCollaborativeSession: "Comenzando sesión...",
        },
        ExerciseModal: {
            resetWarning:
                "Si reinicias, todos los problemas de esta colección se reiniciarán (incluido este) y se te llevará a la página de colecciones ¿deseas continuar?",
            resetCollectionTitle: "Reiniciar colección",
            congratulations: "¡Felicidades!",
            understoodBack: "¡Entendido! Llévame a las colecciones",
            finishedCollection:
                "Has terminado el último problema de la colección",
            understoodNextProblem: "¡Entendido! Llévame al siguiente problema",
            finishedCollectionTitle: "¡Colección terminada!",
            finishedCollectionTitleByMaxActions:
                "Colección terminada: Número de acciones permitidas superado",
            finishedCollectionDescription:
                "Se ha terminado el último problema de la colección",
        },
        Error404: {
            error404:
                "¡Ups! Parece que has encontrado una página que no existe en el mundo de las matemáticas. 📚📏📐🧮",
            understoodBack404: "Volver a inicio",
        },
        TestPostProblem: {
            evaluation: "Evaluación",
        },
        SAM: {
            valuesWarning:
                "Por favor, selecciona los tres maniquíes que mejor representen tus emociones durante la prueba",
            description:
                "Nuestro Test SAM te permite evaluar tus emociones después de resolver problemas matemáticos. Simplemente elige al maniquí que reflejen tus sentimientos, ayudándonos a entenderte mejor y mejorar tu experiencia de aprendizaje.",
            sad: "Triste",
            cheerful: "Alegre",
            quiet: "Tranquilo",
            active: "Activo",
            dependent: "Dependiente",
            independent: "Independiente",
        },
        Difficulty: {
            valuesWarning:
                "Por favor, selecciona el nivel de dificultad que creas que tiene este problema",
            description:
                "¿Como de dificil te ha resultado resolver este problema?",
        },
        ProblemTitleRow: {
            helpAllowed: "ayudas permitidas",
            actionAllowed: "acciones permitidas",
        },
        AdminCollectionSettingPage: {
            areHelpIntentsLimited: "Restringir número de ayudas",
            areActionsLimited: "Restringir número de acciones",
            helpAllowed: "ayudas permitidas",
            actionAllowed: "acciones permitidas",
            updateCollection: "Actualizar colección",
            conversational: "Conversacional",
            SelectionMode: "Selección del próximo problema",
            GroupStrategy: "Estrategia para hacer grupos",
            SelectionTestPostProblem: "Realizar test al finalizar el problema",
            EmotionalReport: "Pedir informe emocional",
            Interface: "Interfaz",
            ExpressionSimplification: "Simplificación de expresiones",
            ForceExpressionDescription: "Forzar descripción de expresiones",
            QuantitiesInCombo: "Cantidades en el desplegable",
            EqActivation: "Activación de escritura de ecuaciones",
            ForceDifferentDescription: "Forzar diferentes expresiones",
            NextActionChoice: "Determinación de la acción siguiente",
            SolicitedFeedbackModel: "Modo de pistas",
            HelpLevel: "Nivel de ayuda inicial",
            Sound: "Sonido",
            OnDemandHelp: "Ayudas a demanda",
            InstructionalModel: "Modelo instruccional",
            HelpType: "Interfaz de ayuda",
            ShowFeedback: "Mostrar Feedback",
            ForceResolution: "Forzar resolución de expresiones",
            GraphSolved: "Gráfico resuelto",
            QuantitiesButtons: "Información en los botones",
            QuantityDefinition:
                "Definición de cantidades numéricas en el enunciado",
            Timer: "Utilizar temporizador",
            SettingsDescription:
                "Si se modifican los ajustes durante la realización de un problema, se deberá recargar el problema por parte del usuario.",
            settings: "Ajustes",
            settings_correct: "¡Configuración actualizada correctamente!",
            settings_incorrect: "¡Ups! Parece que algo ha ido mal",
            settings_incompatible:
                "¡Solo las colecciones individuales pueden ser de selección Usuario!",
            SAM: "SAM",
            NoTestPostProblem: "No",
            RMS: "RMS",
            Difficulty: "Dificultad",
            Number: "Número",
            Text: "Texto",
            Graphic: "Gráfica",
            TextAndGraphic: "Texto y Gráfica",
            Zero: "Cero",
            AsSpecifiedInTheCML: "Como se especifique en el CML",
            Intelligent: "Inteligente",
            Simple: "Simple",
            Flexible: "Flexible",
            Random: "Aleatorio",
            Deterministic: "Determinista",
            ByTheSystem: "Por el sistema",
            AsSpecifiedInTheXML: "Como se especifique en el XML",
            ByTheUser: "Por el usuario",
            Always: "Siempre",
            IfPossible: "Si es posible",
            IfAllQuantitiesAreDefined: "Si todas la cantidades están definidas",
            OnlyRelevant: "Solo las relevantes",
            OnlyThosePartOfResult: "Solo las que forman parte del resultado",
            All: "Todas",
            Hide: "Ocultar",
            None: "Ninguna",
            Fractions: "Fracciones",
            Total: "Total",
            Algebraic: "Algebraica",
            Arithmetic: "Aritmética",
            Automatic: "Automática",
            Equation: "Ecuación",
            Equations: "Ecuaciones",
            yes: "Si",
            no: "No",
            maxAllowedActions: "Acciones permitidas",
            maxAllowedHelp: "Ayuda (explícita) permitida",
            tooltipStrategy:
                "Define la estrategía a seguir para la resolución de la colección",
            tooltipMode: "Define el modo en que los problemas van a ...",
            tooltipEmotialReport:
                "Permite la elaboración de un informe emocianal del alumno.",
            tooltipInterface:
                "Define la interfaz a utilizar en el transcurso de los problemas: Alegebraicas ...",
            tooltipConversational: "",
            tooltipSimplifyExpressions: "",
            tooltipForceExpressionsDescriptions: "",
            tooltipShowQuantities: "",
            tooltipWritingEquations: "",
            tooltipDifferentExpressions: "",
            tooltipDetermineNextAction: "",
            tooltipTracksMode: "",
            tooltipInitialLevelHelp: "",
            tooltipSound: "",
            tooltipAidOnDemand: "",
            tooltipInstructionalModel: "",
            tooltipHelpInterface: "",
            tooltipShowFeedback: "",
            tooltipForceExpressionResolution: "",
            tooltipSolvedGraph: "",
            tooltipInformationButtons: "",
            tooltipDefinitionNumericalQuantitiesStatement: "",
            tooltipUseTimer: "",
            tooltipTestAfterProblem: "",
            tooltipMaxAllowedHelp:
                "Número de veces que el usuario puede pedir ayuda al sistema por medio del comando 'ayuda'. Si el usuario gasta todas las ayudas disponibles, no se mostrarán más",
            tooltipMaxAllowedActions:
                "Número de acciones permitidas al usuario. Si sobrepasa el número de acciones permitidas, se termina la resolución del problema.",
        },
    },
    en: {
        helpButton: "Help ℹ️",
        tutorial: "Tutorial",
        chat: "Chat",
        name: "Name",
        surname: "Surname",
        loading: "Loading",
        email: "E-mail",
        birthDate: "Date of birth",
        language: "Language",
        spanish: "Spanish",
        english: "English",
        group: "Group",
        groups: "Groups",
        timeZone: "Time zone",
        admin: "Administrator",
        role: "Role",
        users: "Users",
        Male: "Male",
        Female: "Female",
        NonBinary: "Non-binary",
        NotAnswered: "Prefer not to say",
        male: "Male",
        female: "Female",
        nonBinary: "Non-binary",
        notAnswered: "Prefer not to say",
        yes: "Yes",
        no: "No",
        sex: "Sex",
        roles: "Roles",
        username: "Username",
        password: "Password",
        User: "User",
        Sequence: "Sequence",
        SEQUENCE: "Sequence",
        USER: "User",
        instructionalModel: "Instruccional-Model",
        mod: "Moderator",
        back: "Back",
        active: "Active",
        new: "New",
        registerMassive: "Register multiple users",
        massive: "Massive",
        new_problem: "New problem",
        new_collection: "New collection",
        new_group: "New group",
        problems: "Problems",
        collections: "Collections",
        problem_name: "Problem name",
        problem_text: "Problem text",
        filename: "File name",
        enter_problem_xml: "Please enter the problem XML",
        enter_collection_filename: "Please enter the collection file name",
        search_select_problem: "Search or select a problem...",
        group_modified_incorrect: "Unable to modify the group",
        search_problem: "Search for a problem...",
        select_problem: "Select a problem",
        no_options: "No options",
        empty_collection: "Empty collection",
        current_problems: "Current problems",
        RANDOM: "Random",
        SINGLE: "Individual",
        PAIR: "Pairs",
        AGENTS: "Agents",
        congratulationsCollection:
            "Congratulations for solving the problem(s): You are one step closer to mastering mathematics!",
        delete: "Delete",
        logs: "Logs",
        event: "Event",
        filter: "Filter",
        operand: "Operand",
        send: "Send",
        resetProblem: "Are you sure you want to reset the problem?",
        cancel: "Cancel",
        confirmReset: "Understood, reset it!",
        pageNotFound:
            "Whoops! We can't seem to find the page you are looking for",
        backPreviousPage: "Go back",
        serchBarText: "Search problem",
        serchBarButton: "Clear",
        update: "Update",
        new_instructionalModel: "New instructional model",
        edit_instructionalModel: "Edit instructional model",
        isDefaultInstructionalModel: "Instructional modal by default",
        DidacticSeq: {
            didacticSeq: "Didactic Sequence",
            newDidacticSeq: "New Didactic Sequence",
            createDidacticSeq: "Creat Didactic Sequence",
            createIncorrect: "The Didactic Sequence could not be created",
            succesNewDidacticSeq: "Didactic Sequence successfully created!",
            succesModDidacticSeq: "Didactic Sequence successfully modified!",
            modifieErrorDidacticSeq:
                "The Didactic Sequence could not be modified",
            editDidacticSeq: "Edit Didactic Sequence",
        },
        alert: {
            ok: "Element added!!",
            repeated: "Error: an element with this name already exists",
            missname: "Error: indicates a name for the element",
            success: "success",
            danger: "danger",
        },
        error: {
            name: "Please, write a valid name",
            fileType: "Only ZIP files are valid",
        },
        AdminNewInstructionalTemplatePage: {
            zipFile: "ZIP file",
            succesNew: "The instructional model has been created",
        },
        AdminEditInstructionalTemplatePage: {
            zipFile: "ZIP file",
            succesEdit: "The instructional model has been modified",
        },
        UserDataTable: {
            passwordChange: "Required in case of password change",
            register_correct: "User registered successfully!",
            register_usernameIncorrect:
                "Oops! Someone with that username already exists",
            register_incorrect: "Oops! Something went wrong",
            updateUser_correct: "User updated successfully!",
            password_error: "The passwords entered do not match",
            editUser: "Edit user",
            account: "Personal information",
            register: "Register user",
            repeatPassword: "Repeat password",
            surnames: "Surnames",
            accept: "Accept",
        },
        SignUpMassive: {
            massiveRegister_correct: "Users registered successfully!",
            massiveRegister_usernameIncorrect:
                "Oops! There is already a user with one of those names",
            massiveRegister_incorrect: "",
            register_users: "Register users",
            prefix: "Prefix",
            numUsers: "Number of users",
        },
        ListUsers: {
            create_user: "Register a user",
            massive_register: "Massive registration",
        },
        LoginForm: {
            login_error: "Incorrect username or password",
            login: "Log in",
        },
        AdminCollectionTable: {
            hidden: "Hidden",
            edit_collection: "Edit collection",
            enter_collection_name:
                "Por favor, introduzca el nombre de la colección",
            collection_created_correct: "Collection created successfully!",
            collection_modified_correct: "Collection modified successfully!",
            collection_created_incorrect: "Unable to create the collection",
            collection_modified_incorrect: "Unable to modify the collection",
            group_created_correct: "Group created successfully!",
            group_modified_correct: "Group modified successfully!",
            group_created_incorrect: "Unable to create the group",
            group_name_already_exists: "A group with that name already exists",
            available_problems: "Available problems",
            create_collection: "Create collection",
        },
        AdminGroupTable: {
            edit_group: "Edit group",
            enter_group_name: "Group name",
            available_users: "Available users",
            available_collections: "Available collections",
        },
        SelectSearch: {
            search_select_options: "Search or select one or more options...",
        },
        FinishedCollectionModal: {
            congratulations: "Congratulations!",
            understoodBack: "Understood! Take me back to collections",
            finishedCollection:
                "You have finished the last problem of the collection",
        },
        ProblemTable: {
            edit_problem: "Update problem",
            create_problem: "Create problem",
            problem_created_correct: "Problem created successfully!",
            problem_name_already_exists:
                "A problem with that name already exists",
            error_updating_problem: "There was an error updating the problem",
            error_creating_problem: "There was an error creating the problem",
            problem_updated_correct:
                "The problem has been updated successfully!",
        },
        AdminLogsPage: {
            allTypes: "*ALL_TYPES*",
            type: "Type",
            download: "Download",
            dateTime: "Date and time",
            subject: "User",
            roomId: "RoomId",
            intent: "Intent",
            wrapperId: "Collection",
            deleteFilters: "Delete filters",
            problem: "Problem",
            from: "From",
            to: "To",
        },
        HomePage: {
            problems_info:
                "Each collection has a series of associated problems, and I will help you solve them!",
            users_info:
                "You can change your name, password, date of birth, and more!",
            logout_info:
                "You can log out, and I will be waiting for us to solve more math problems!",
            logoutWarning:
                "If you log out, all the collections (and problems) you were working on will be reset. Are you sure you want to log out?",
            care: "Attention!",
            logout: "Log out",
            editProfile: "Edit profile",
            letsDoMath: "Let's do math!",
            welcomeHomeMessage:
                "Hello boys and girls! Do you want to improve your math skills? Well, welcome to TUTORCHAT! We are an artificial intelligence application designed to help you learn and improve your knowledge in a fun and motivating environment. With our adaptive tool, you can practice problems of different difficulty levels and learn through fun games and activities. Additionally, you can work at your own pace and get feedback and tips to overcome areas where you have difficulties. So don't wait any longer and let's start learning together! We are sure that you can improve your math skills and have fun at the same time!",
        },
        NavigationBar: {
            home: "Home",
            edit_profile: "Edit profile",
            administration: "Administration",
        },
        CollectionsDataTable: {
            sequence: "Sequence",
            onProgress: "In progress",
            selection: "Problem selection",
            groupStrategy: "Collaborative",
            pair: "Pairs",
            timer: "Timer",
            info: "Information",
            helpIntroduction:
                "Remember! In a collection, there can be one or more mathematical problems (which you will need to solve). Additionally, each collection may have different settings:",
            nextProblemSelection: "Selection of the next problem",
            collaborativeInfo:
                "Do you choose the problem, or do you do all the ones in the collection?",
            manual: "Manual",
            manualInfo:
                "Choose a problem. When you finish it, you will have completed the collection (if the collection only has one problem, you will start solving it directly).",
            sequenceInfo:
                "You will need to solve all the problems in the collection, one after the other.",
            individualOrCollaborative: "Individual or Collaborative",
            individualOrCollaborativeInfo:
                "You can solve the problem by yourself (individual) or with others (collaborative).",
            timerInfo:
                "If you see this icon, it means that you will have to solve the problem before time runs out.",
            onProgressInfo:
                "If you have left... it's okay! You can continue solving the last problem where you left off.",
            finalInfo:
                "Remember to come back here if you forget something. Good luck!",
            resumeLastCollection: "Resume solving collection",
        },
        AdminCollectionPage: {
            zeroElementsSelected: "0 elements selected",
            element: "element",
            elements: "elements",
            selected: "selected",
            rowspage: "Rows per page:",
            of: "of",
        },
        MessageBar: {
            sendActionHand: "Send action ☝️",
            sendActionDescription: "You can write an action (equation, variable, etc.) and click a button to send it to the tutor. 🤖🎓",
            sendAction: "Send action to tutor 🤖🎓",
            messagesOnlyToUsers: "Messages to users 👨‍🎓👩‍🎓",
            messagesToUsersAndTutor: "Messages to Tutor 🤖🎓",
        },
        ConversationPage: {
            languageHeader: "english",
            waitingReports:
                "Let's wait for the rest of our classmates to submit their form",
            runOutOfAllowedActions: "¡No more actions are allowed!",
            skipProblem: "Skip problem",
        },
        LeftPanel: {
            notes: "Notes",
            equations: "Equations",
        },
        AdminCollectionEditorPage: {
            file: "File",
        },
        Notebook: {
            erase: "Erase",
            save: "Save",
        },
        Chat: {
            chat: "Chat",
        },
        FinishedProblemBar: {
            continue:
                "Congratulations! You have solved the problem. - Continue",
        },
        TimedOutModal: {
            timedOutTitle: "Oops! The clock says it's time to finish 🕒",
            timedOutDescriptionLastProblem:
                "Time to solve our last problem has ended, see you soon!",
            timedOutDescription:
                "Tick-tock! Time's up, but we can keep solving problems",
        },
        ResetButton: {
            reset: "Reset",
        },
        BookshelfLoader: {
            startingCollaborativeSession: "Starting session...",
        },
        ExerciseModal: {
            resetWarning:
                "If you reset, all the problems in this collection will be reset (including this one), and you will be taken to the collections page. Do you want to proceed?",
            resetCollectionTitle: "Reset Collection",
            congratulations: "Congratulations!",
            understoodBack: "Understood! Take me to the collections",
            finishedCollection:
                "You have completed the last problem in the collection",
            understoodNextProblem: "Understood! Take me to the next problem",
            finishedCollectionTitle: "Collection completed!",
            finishedCollectionTitleByMaxActions:
                "Collection completed: The allowed number of max actions has been reached",
            finishedCollectionDescription:
                "The last problem in the collection is finished",
        },
        Error404: {
            error404:
                "Oops! It seems like you've stumbled upon a page that doesn't exist in the world of mathematics. 📚📏📐🧮",
            understoodBack404: "Return to Home",
        },
        SAM: {
            evaluation: "Evaluation",
            valuesWarning:
                "Please, select the three manikins that best represent the emotions you had during the test",
            description:
                "Our SAM Test allows you to assess your emotions after solving math problems. Simply choose the manikin that reflects your feelings, helping us understand you better and enhance your learning experience.",
            sad: "Sad",
            cheerful: "Cheerful",
            quiet: "Quiet",
            active: "Active",
            dependent: "Dependent",
            independent: "Independent",
        },
        Difficulty: {
            valuesWarning:
                "Please, select the difficulty level you think this problem has",
            description: "How difficult did you find solving this problem?",
        },
        ProblemTitleRow: {
            helpAllowed: "help(s) allowed",
            actionAllowed: "action(s) allowed",
        },
        AdminCollectionSettingPage: {
            restrictNumberOfQuantities: "Restrict number of quantities",
            
            areHelpIntentsLimited: "Restrict number of help intents",
            areActionsLimited: "Restrict number of action intents",
            helpAllowed: "help(s) allowed",
            actionAllowed: "action(s) allowed",
            quantitiesAllowed: "quantities allowed",
            updateCollection: "Update collection",
            conversational: "Conversational",
            SelectionMode: "Selection of the next problem",
            GroupStrategy: "Grouping strategy",
            SelectionTestPostProblem:
                "Perform a test at the end of the problem",
            EmotionalReport: "Request emotional report",
            Interface: "Interface",
            ExpressionSimplification: "Expression simplification",
            ForceExpressionDescription: "Force expression description",
            QuantitiesInCombo: "Quantities in the dropdown",
            EqActivation: "Equation writing activation",
            ForceDifferentDescription: "Force different expressions",
            NextActionChoice: "Determination of the next action",
            SolicitedFeedbackModel: "Hint mode",
            HelpLevel: "Initial help level",
            Sound: "Sound",
            IfAllQuantitiesAreDefined: "If all quantities are defined",
            OnDemandHelp: "On-demand help",
            InstructionalModel: "Instructional model",
            HelpType: "Help interface",
            ShowFeedback: "Show Feedback",
            ForceResolution: "Force expression resolution",
            GraphSolved: "Solved graph",
            QuantitiesButtons: "Information on the buttons",
            QuantityDefinition:
                "Definition of numerical quantities in the statement",
            Timer: "Use timer",
            SettingsDescription:
                "If settings are modified during the completion of a problem, the user should reload the problem.",
            settings: "Settings",
            settings_correct: "Settings updated successfully!",
            settings_incorrect: "Oops! Something went wrong",
            settings_incompatible:
                "Only individual collections can be User-selected!",
            SAM: "SAM",
            noTestPostProblem: "No",
            RMS: "RMS",
            Number: "Number",
            Text: "Text",
            Graphic: "Graphic",
            TextAndGraphic: "Text and Graphic",
            Zero: "Zero",
            AsSpecifiedInTheCML: "As specified in the CML",
            Intelligent: "Intelligent",
            Simple: "Simple",
            Flexible: "Flexible",
            Random: "Random",
            Deterministic: "Deterministic",
            ByTheSystem: "By the system",
            AsSpecifiedInTheXML: "As specified in the XML",
            ByTheUser: "By the user",
            Always: "Always",
            IfPossible: "If possible",

            OnlyRelevant: "Only relevant",
            OnlyThosePartOfResult: "Only those part of the result",
            None: "None",
            Hide: "Hide",
            All: "All",
            Fractions: "Fractions",
            Total: "Total",
            Algebraic: "Algebraic",
            Arithmetic: "Arithmetic",
            Automatic: "Automatic",
            Equation: "Equation",
            Equations: "Equations",
            yes: "Si",
            no: "No",
            maxAllowedHelp: "Explicit help",
            maxVariableDefinition: "DEFINE QUANTITIES",
            maxAllowedActions: "Allowed actions",
            tooltipStrategy:
                "Define la estrategía a seguir para la resolución de la colección",
            tooltipMode: "Define el modo en que los problemas van a ...",
            tooltipEmotialReport:
                "Permite la elaboración de un informe emocianal del alumno.",
            tooltipInterface:
                "Define la interfaz a utilizar en el transcurso de los problemas: Alegebraicas ...",
            tooltipConversational: "",
            tooltipSimplifyExpressions: "",
            tooltipForceExpressionsDescriptions: "",
            tooltipShowQuantities: "",
            tooltipWritingEquations: "",
            tooltipDifferentExpressions: "",
            tooltipDetermineNextAction: "",
            tooltipTracksMode: "",
            tooltipInitialLevelHelp: "",
            tooltipSound: "",
            tooltipAidOnDemand: "",
            tooltipInstructionalModel: "",
            tooltipHelpInterface: "",
            tooltipShowFeedback: "",
            tooltipForceExpressionResolution: "",
            tooltipSolvedGraph: "",
            tooltipInformationButtons: "",
            tooltipDefinitionNumericalQuantitiesStatement: "",
            tooltipUseTimer: "",
            tooltipTestAfterProblem: "",
            tooltipMaxVariableDefinition: "Maximum number of quantities the user is allowed to define",
            tooltipMaxAllowedHelp:
                "Number of 'help' prompt allowed to the user. If the user runs out of allowed 'help' prompts, none will be shown",
            tooltipMaxAllowedActions:
                "Number of actions allowed to the user. If the number of permitted actions is exceeded, the problem resolution is terminated.",
        },
    },
});

export default strings;
